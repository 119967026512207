import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Page from "../components/Page"
import FancyHeader from "../components/blocks/FancyHeader"

const DynamicPage = ({ data }) => {
  const { contentfulPage } = data
  const { fancyTitle, fancySubtitle } = contentfulPage

  var zeroTop = false
  var fancyBlock = ""

  if (fancyTitle || fancySubtitle) {
    zeroTop = true
    fancyBlock = <FancyHeader title={fancyTitle} subTitle={fancySubtitle} />
  }

  // Zero Top Pulls The Page Up to Zero.
  return (
    <Page zeroTop={zeroTop} {...contentfulPage}>
      {fancyBlock}
    </Page>
  )
}

DynamicPage.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({
      fancyTitle: PropTypes.string,
      fancySubtitle: PropTypes.string,
    }),
  }),
}

export default DynamicPage

export const pageQuery = graphql`
  query DynamicPage($rallistaId: String!) {
    contentfulPage(rallistaId: { eq: $rallistaId }) {
      contentful_id
      seoTitle
      rallistaId
      seoDescription
      fancyTitle
      fancySubtitle
      sections {
        ... on ContentfulFeatureSection {
          internal {
            type
          }
          title
          buttonLink
          buttonTitle
          body {
            raw
          }
          justifyImage
          media {
            title
            description
            contentful_id
            file {
              url
              fileName
              contentType
              details {
                size
              }
            }
          }
          contentful_id
        }
        ... on ContentfulHeroSection {
          internal {
            type
          }
          title
          background {
            file {
              contentType
              fileName
              url
              details {
                size
              }
            }
            contentful_id
            title
            description
          }
          titleImage {
            file {
              contentType
              fileName
              url
              details {
                size
              }
            }
            contentful_id
            title
            description
          }
          subTitle
          justifyActionBox
          actionBox {
            buttonLink
            buttonTitle
            body {
              raw
            }
            contentful_id
            title
          }
          contentful_id
        }
        ... on ContentfulTextSection {
          internal {
            type
          }
          id
          title
          richText {
            raw
          }
          contentful_id
        }
      }
    }
  }
`
